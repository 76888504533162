.notFoundPage {
  margin-top: 60px;

  h1 {
    font-family: 'Anton', sans-serif;
    text-align: center;
    font-size: 5em;
    letter-spacing: 0.1em;
    color: rgb(255, 255, 255);
  }
  h3 {
    font-weight: normal;
    font-family: sans-serif;
    font-size: 14px;
    text-align: center;
    color: rgb(255, 255, 255);
  }
  .images {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: center;
  }

  a.link {
    font-weight: normal;
    font-family: sans-serif;
    font-size: 14px;
    text-align: center;
    color: rgb(255, 255, 255);
    display: inline-block;
    text-decoration: none;
  }

  a.link:hover,
  a.link:visited::hover {
    font-weight: bold;
    cursor: pointer;
    color: rgb(23, 190, 187);
    text-decoration: none;
  }

  a.link,
  a.link:visited {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    text-decoration: none;
  }
}
