.aboutMe {
  width: 100%;
  background-color: rgba(51, 51, 51, 1);
  color: #ffffff;
  display: grid;
  align-items: center;
  justify-content: center;
  animation: darkness 1s 2s ease-in forwards;
  overflow: hidden;
  margin-top: 60px;
}

.aboutMe h2 {
  font-weight: normal;
  text-align: center;
  line-height: 50px;
}

.line {
  width: 100%;
  background-color: rgba(205, 83, 52, 1);
  height: 5px;
}

.aboutMe h2 strong {
  text-transform: uppercase;
  color: rgba(23, 190, 187, 1);
  font-weight: normal;
  letter-spacing: 5px;
  font-family: 'Anton', sans-serif;
}

.sectionMyLife {
  display: grid;
  background: rgba(51, 51, 51, 1);
  grid-template-rows: 10vh 20px min-content;
  padding: 60px 40px;
  align-items: center;
  overflow: hidden;
  grid-row-gap: 30px;
}

.right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 100px;
  background: #ffffff;
}

h1 {
  color: #ffffff;
}

.imageAndText {
  position: relative;
  width: 100%;
  height: 100vh;
}

.textAndLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clipped {
  width: 250px;
  height: 250px;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 1;
}

.border {
  position: absolute;
  left: 23%;
}

.aboutMe {
  grid-template-columns: 80%;
}

.path {
  stroke-dasharray: 1116.4564208984375 1116.4564208984375;
  stroke-dashoffset: 1116.4564208984375;
  animation: drawLine 1s 1s ease-in-out forwards, fillOut 1s 2s ease-in forwards;
}

.path1 {
  stroke-dasharray: 1440.060302734375 1440.060302734375;
  stroke-dashoffset: 1440.060302734375;
  animation: drawLine 1s 1s ease-in-out forwards;
}

.path2 {
  stroke-dasharray: 1440.060302734375 1440.060302734375;
  stroke-dashoffset: 1440.060302734375;
  animation: drawLine 10s 1s ease-in-out infinite;
}

.imageInSvg,
.imageInSvgMobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageInSvgMobile {
  .border1 {
    z-index: 3;
  }
  .clipped {
    position: absolute;
    z-index: 2;
  }
}

.imageInSvg {
  display: none;
}

.textualInformation h4 {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.textualInformation h4:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

figure {
  margin: 0;
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes darkness {
  to {
    background-color: #000000;
  }
}

@keyframes fillOut {
  from {
    fill: rgba(0, 0, 0, 0);
  }
  to {
    fill: rgba(51, 51, 51, 1);
  }
}

.fifth {
  display: none;
}
/* media queries  */

@media (min-width: 1125px) {
  .fifth {
    display: grid;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    grid-auto-rows: min-content;
    grid-gap: 20px;
    padding: 20px;
    overflow: hidden;

    .arrowLine {
      position: absolute;
      width: 90vw;
    }
    .images {
      margin: 10px auto 6px;
      width: 100vw;
      display: grid;
      grid-template-columns: repeat(5, auto);
    }
  }
  .aboutMe {
    height: calc(100vh - 40px);
    margin-top: 40px;
  }

  .imageInSvgMobile {
    display: none;
  }

  .imageInSvg {
    display: flex;
  }

  .aboutMe {
    grid-template-columns: 40% 40%;
    grid-template-rows: auto;
  }

  .line {
    height: 60vh;
    width: 5px;
    background-color: rgba(205, 83, 52, 1);
  }

  .border {
    position: relative;
    left: 23%;
  }

  .border1 {
    position: relative;
    z-index: 2;
  }

  .borderImageOne {
    position: relative;
    z-index: 2;
    left: 260px;
    width: 200px;
  }

  .sectionMyLife {
    grid-template-columns: 20% 20px 1fr;
    height: 100vh;
    padding: 40px;
    align-items: center;
    overflow: hidden;
    grid-template-rows: auto;
  }

  .clipped {
    width: 300px;
    height: 300px;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 1;
  }

  .clippedKinto {
    width: 200px;
    height: 150px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    z-index: 1;
  }
  .clippedSF {
    width: 200px;
    height: 250px;
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    z-index: 1;
  }
  .clippedInverted {
    width: 200px;
    height: 200px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    z-index: 1;
  }

  .clippedPortraitInverted {
    width: 200px;
    height: 300px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    z-index: 1;
  }

  .clippedPortrait {
    width: 200px;
    height: 300px;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 4;
  }
  .clippedBoxing {
    width: 200px;
    height: 200px;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 1;
  }
}
