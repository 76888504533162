@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Homemade+Apple|Raleway');

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

html {
  background-color: #000000;
}

* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

.site-nav {
  position: fixed;
  margin: 0;
  padding: 10px 10px;
  top: 0;
  right: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, min-content);
  width: 100%;
  justify-items: start;
  z-index: 3;
  background-color: #000000;
  min-height: 40px;
  grid-gap: 10px;
  a {
    font-size: 1em;
    line-height: 40px;
  }

  @media (min-width: 1125px) {
    a {
      min-height: 40px;
      line-height: 20px;
    }
  }
}

ul li {
  position: relative;
  // margin-right: 15px; TODO: check this isn't janking somewhere else
  width: min-content;
  text-align: center;
}

a {
  text-decoration: none;
}

a:hover {
  color: rgba(205, 83, 52, 1);
}

a.active {
  text-decoration: line-through;
  color: rgba(205, 83, 52, 1);
}

h1 {
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  font-size: 2.5em;

  color: #ffffff;
}

@media (min-width: 1125px) {
  h1 {
    // min-height: 40px;
    // line-height: 20px;
    font-size: 4em;
  }
}

h4 {
  font-family: 'Ruda', sans-serif;
  color: #ffffff;
  font-weight: normal;
}

h2 {
  font-family: 'Ruda', sans-serif;
  color: #ffffff;
}

a {
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

/* VARIABLES */
/* RGB */
$grey: rgba(51, 51, 51, 1);
$green: rgba(27, 153, 139, 1);
$lightGreen: rgba(23, 190, 187, 1);
$red: rgba(205, 83, 52, 1);
$black: rgba(0, 0, 0, 1);
