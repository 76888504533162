.experiencePage {
  min-height: 100vh;
}

.right h2 {
  margin-top: 20px;
  font-size: 1em;
}

.right h2 b {
  font-weight: bold;
  font-family: 'Anton', sans-serif;
  letter-spacing: 0.1em;
}

.triangle img,
.triangle2 img,
.triangle3 img {
  display: none;
}

.line,
.line2,
.line3,
.line4 {
  height: 5px;
  width: 100%;
  position: relative;
  left: 0;
  background-color: rgba(27, 153, 139, 1);
}

.line2 {
  background-color: rgba(205, 83, 52, 1);
}

.line3 {
  background-color: rgba(23, 190, 187, 1);
}

.line4 {
  background-color: rgba(205, 83, 52, 1);
}

@media (min-width: 1125px) {
  .triangle img,
  .triangle2 img,
  .triangle3 img {
    display: block;
  }

  .line,
  .line2,
  .line3,
  .line4 {
    height: 60vh;
    width: 5px;
    position: relative;
    left: 0;
    background-color: rgba(27, 153, 139, 1);
  }

  .line,
  .line2,
  .line3,
  .line4 {
    height: 60vh;
    width: 5px;
    position: relative;
    left: 0;
    background-color: rgba(27, 153, 139, 1);
  }

  .line2 {
    background-color: rgba(205, 83, 52, 1);
  }

  .line3 {
    background-color: rgba(23, 190, 187, 1);
  }

  .line4 {
    background-color: rgba(205, 83, 52, 1);
  }
}

.triangle {
  position: absolute;
  bottom: -175px;
  left: 35%;
}

.triangle img {
  height: 250px;
  width: 250px;
}

.triangle2 {
  position: absolute;
  bottom: 0;
  left: 400px;
}

.triangle2 img {
  height: 150px;
  width: 150px;
}

.triangle3 {
  position: absolute;
  left: 15%;
}

.triangle3 img {
  height: 50px;
  width: 50px;
}

.first,
.second,
.third,
.fourth {
  max-width: 100%;
  padding: 40px;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 40px;
  align-items: center;
}

.first {
  margin-top: 40px;
}

.first .right h2 b {
  color: rgba(27, 153, 139, 1);
}

.country {
  letter-spacing: 0.5em;
}

.second {
  grid-template-columns: 1fr;
}

.second .right {
  align-self: center;
}
.second .right h2 b {
  color: rgba(205, 83, 52, 1);
}
.third .right h2 b {
  color: rgba(23, 190, 187, 1);
}

.right {
  overflow: hidden;
}

.first {
  background-color: rgba(0, 0, 0, 1);
}

.second {
  background-color: rgba(51, 51, 51, 1);
}

.third {
  background-color: rgba(0, 0, 0, 1);
}

.fourth {
  background-color: rgba(51, 51, 51, 1);
  .hobbiesBackground {
    display: none;
  }
}

.arrows {
  display: none;
}

/* // fixing the lines */

.clipped {
  width: 400px;
  height: 400px;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 1;
}

@media (min-width: 1125px) {
  .arrows {
    display: block;
    height: 54px;
    position: absolute;
    right: 200px;
    bottom: 50px;
  }

  .arrow2 {
    display: block;
    height: 100px;
    position: relative;
    right: 0;
    bottom: 0;
  }

  .left {
    padding-left: 10px;
  }
  .first,
  .second,
  .third,
  .fourth {
    grid-template-columns: 300px 20px auto;
    height: 100vh;
  }

  .first {
    margin-top: 40px;
  }

  .second {
    grid-template-columns: 300px 20px 1fr 1fr;
  }

  .right h2 {
    color: #ffffff;
    margin-bottom: 30px;
    padding-right: 20px;
    font-weight: normal;
  }

  .right h2:last-child {
    margin-bottom: 0;
  }

  .fourth .right {
    z-index: 2;
    h2 {
      background-color: rgba(51, 51, 51, 0.5);
      width: max-content;
    }
  }
  .fourth .hobbiesBackground {
    display: block;
    position: absolute;
    transform: scale(1.5);
    transform-origin: right top;
    right: 0;
    z-index: 1;
    opacity: 0.7;
    margin: 20px;
  }
}
