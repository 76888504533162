$lightGreen: rgba(23, 190, 187, 1)
$green: rgba(27, 153, 139, 1)

.portfolio
  min-height: 100vh
  padding: 60px 40px 40px
  background-color: rgba(0, 0, 0, 1)

  .top
    display: grid
    grid-template-rows: min-content 20px min-content
    padding: 60px 0
    align-items: center
    overflow: hidden
    grid-row-gap: 30px
  .line
    width: 100%
    background-color: rgba(205, 83, 52, 1)
    height: 5px

.portfolio h2, .scribble, .cubeHeading
  cursor: pointer
  color: $lightGreen
  align-self: center

.titles
  display: grid
  justify-content: center
  align-content: center
  grid-gap: 20px
  margin: 0px auto 0px

.titles
  h2
    color: #ffffff
    position: relative
    transition: all 0.2s ease
    text-align: center
    &.highlightedHeading
      color: $lightGreen

  h2:hover
    color: $lightGreen
    .pathSquareBottom
      animation: moveAround 7s ease infinite
    .pathSquareTopRight
      animation: moveAroundRight 7s ease infinite
    .pathSquareTopLeft
      animation: moveSquareTopLeft 7s linear infinite
    .pathSquareTopSmall
      animation: moveSquareTopSmall 7s linear infinite
    .pathSquareBottomSmall
      animation: moveAround 7s linear infinite

.heading:hover, .cubeHeading:hover
  .cube
    animation: spin 7s infinite linear
  h2
    color: $lightGreen

.cubeHeading
  position: relative

.pathSquareTopRight, .pathSquareTopLeft, .pathSquareTopSmall, .pathSquareBottomRight, .pathSquareBottom, .pathSquareBottomSmall
  position: absolute
  transform-origin: 50% 50%

.pathSquareTopRight
  bottom: 0
  right: 20px
  stroke: rgba(255, 255, 255, 0.5)

.pathSquareTopLeft
  top: 0
  left: 0px
  fill: rgba(255, 255, 255, 0.5)

.pathSquareTopSmall
  top: -120%
  left: 35%
  fill: rgba(255, 255, 255, 0.5)

.pathSquareBottom
  top: -140%
  right: 35%
  stroke: rgba(255, 255, 255, 0.5)

.pathSquareBottomRight
  top: 10%
  right: 0
  stroke: rgba(255, 255, 255, 0.5)

.pathSquareBottomSmall
  bottom: -100%
  right: 40%
  stroke: rgba(255, 255, 255, 0.5)

.cube
  -webkit-transform-style: preserve-3d
  // -webkit-animation: spin 7s infinite linear
  position: absolute
  width: 100px
  height: 100px
  top: 50%
  left: 50%
  margin-left: -50px
  margin-top: -50px

.cube div
  width: 100px
  height: 100px
  line-height: 100px
  text-align: center
  border: solid 1px white
  display: block
  position: absolute

.cube  .topCube
  transform: rotateX(90deg)
  margin-top: -50px

.cube  .rightCube
  transform: rotateY(90deg)
  margin-left: 50px

.cube  .bottomCube
  transform: rotateX(-90deg)
  margin-top: 50px

.cube .leftCube
  transform: rotateY(-90deg)
  margin-left: -50px

.cube  .frontCube
  transform: translateZ(50px)

.cube .backCube
  transform: translateZ(-50px) rotateX(180deg)

.images
  width: 70vw
  margin: 0 auto

.scribbles
  display: none
  position: absolute
  width: 150px

.scribblesStatic, .pathSquareTopLeft, .pathSquareTopSmall, .pathSquareBottomRight, .pathSquareBottom, .pathSquareBottomSmall, .cube, .pathSquareTopRight
  display: none

.scribbleOuter
  position: relative

.gallery
  display: grid
  grid-template-rows: 20vh 20px max-content
  grid-gap: 10px

@media (min-width: 1125px)

  .top, .gallery
    grid-template-columns: 20% 20px auto
    min-height: calc(60vh - 160px)
    align-items: center
    overflow: hidden
    grid-template-rows: auto
    grid-gap: 0
    h2
      padding: 0
    .line
      height: 30vh
      width: 5px
      background-color: rgba(205, 83, 52, 1)

  .gallery
    align-items: start

    .line
      height: 95vh
      background-color: $lightGreen

  .links, .linksTests
    padding: 10px 0 20px
    width: 100vw
    display: grid
    grid-template-columns: repeat(5, auto)
    grid-gap: 20px
    justify-content: start
    color: white
    align-items: center
    a
      font-size: 2em

  .linksTests
    padding: 10px 0 50px
    grid-template-columns: repeat(3, auto)

  .titles
    grid-template-rows: repeat(3, 300px)
    justify-content: start
    margin: 0

  .scribblesStatic, .pathSquareTopLeft, .pathSquareTopSmall, .pathSquareBottomRight, .pathSquareBottom, .pathSquareBottomSmall, .cube
    display: block

  .scribblesStatic
    width: 200px
    height: 200px
    position: absolute
    top: 50%
    left: 50%
    margin-left: -100px
    margin-top: -100px

  .scribbleOuter
    display: flex
    justify-content: center
    align-content: center

  .scribbleOuter:hover
    .scribbles
      display: block
      width: 200px
      height: 200px
      display: block
      position: absolute
      top: 50%
      left: 50%
      margin-left: -100px
      margin-top: -100px
    .scribblesStatic
      display: none

@keyframes spin
  0%
    transform: rotateX(-20deg) rotateY(20deg)
  100%
    transform: rotateX(-20deg) rotateY(740deg)

@keyframes moveAround
  0%

    transform: rotate(0deg)
    stroke: rgba(255, 255, 255, 0.5)
  25%

    stroke: rgba($lightGreen, 1)

  50%

    stroke: rgba(255, 255, 255, 0.5)

  75%

    stroke: rgba($lightGreen, 1)

  100%

    transform: rotate(360deg)
    stroke: rgba(255, 255, 255, 0.5)

@keyframes moveAroundRight
  0%
    right: 0px
    transform: scale(1)
    fill: rgba(255, 255, 255, 0.5)
  25%
    right: 10px
    fill: rgba($lightGreen, 1)
  50%
    right: 0px
    transform: scale(1.5)
    bottom: 30px
    fill: rgba(255, 255, 255, 0.5)

  75%
    right: 20px
    fill: rgba($lightGreen, 1)

  100%
    right: 0px
    transform: scale(1)
    fill: rgba(255, 255, 255, 0.5)

@keyframes moveSquareTopLeft
  0%
    left: 0px
    transform: scale(1.5)
    fill: rgba(255, 255, 255, 0.5)
  25%
    left: 10px
    fill: rgba($lightGreen, 1)
  50%
    left: 0px
    transform: scale(1.2)
    top: 30px
    fill: rgba(255, 255, 255, 0.5)

  75%
    left: 20px
    fill: rgba($lightGreen, 1)

  100%
    left: 0px
    transform: scale(1)
    fill: rgba(255, 255, 255, 0.5)

@keyframes moveSquareTopSmall
  0%
    top: -120%
    left: 35%
    transform: scale(1)
    fill: rgba(255, 255, 255, 0.5)
  25%
    top: -125%
    left: 20%
    fill: rgba($lightGreen, 1)
  50%
    top: -120%
    left: 30%
    transform: scale(1.2)
    fill: rgba(255, 255, 255, 0.5)

  75%
    top: -115%
    left: 25%
    fill: rgba($lightGreen, 1)

  100%
    top: -120%
    left: 35%
    transform: scale(1)
    fill: rgba(255, 255, 255, 0.5)

h2.highlightedHeading
  color: $lightGreen
